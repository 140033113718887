<template>
  <div class="mv">
    <ul class="mv-list">
      <li class="mv-illust" v-for="item in 6" :key="item.mv"></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.mv {
  position: relative;
  width: 100%;
  @include pc {
    margin: 0 auto 60px;
  }
  @include sp {
    margin-bottom: 40px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }
  &-illust {
    background-color: aquamarine;
    @include pc {
      width: calc((100% - 20px) / 3);
      height: 350px;
    }
    @include sp {
      width: calc((100% - 10px) / 2);
      height: 185px;
    }
  }
}
</style>
