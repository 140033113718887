<template>
  <ul class="news-list">
    <li class="news-list-one" v-for="item in newslist" :key="item">
      <router-link to="/article/2023-01-04">
        <div class="date">{{ item.date }}</div>
        {{ item.title }}
        <i class="fa-solid fa-arrow-right"></i>
      </router-link>
      <!-- <a href="/news/2022-11-19"> </a> -->
    </li>
  </ul>
</template>

<script>
import newslist from "@/assets/json/news.json";

export default {
  data() {
    return {
      newslist: newslist,
    };
  },
  props: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.news {
  &-list {
    position: relative;
    @include pc {
      padding-left: 30px;
      width: 100%;
      font-size: 1.11em;
    }
    @include sp {
      padding-left: 10px;
      font-size: 1.07em;
    }
  }
  &-list-one {
    position: relative;
    // border-bottom: 2px solid #000;
    padding: 0 0.5em 0.7em;
    transition: 300ms;
    &:not(:last-child) {
      margin-bottom: 2em;
    }
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      bottom: 0;
      background: #000;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    .date {
      position: relative;
      width: fit-content;
      padding: 0.3em 0.8em;
      border: 2px solid #000;
      background-color: #000;
      color: #fff;
      transition: all 200ms ease-in-out;
      @include pc {
        margin-bottom: 1em;
        left: -30px;
        font-size: 0.8em;
      }
      @include sp {
        margin-bottom: 1em;
        left: -10px;
        font-size: 0.85em;
      }
    }
    .fa-arrow-right {
      position: absolute;
      right: 10px;
      transition: 200ms;
    }
    &:hover {
      // opacity: 0.7;
      .fa-arrow-right {
        right: 0;
      }
      &::after {
        animation: newsborder 1.5s ease-in-out infinite;
      }
      .date {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

@keyframes newsborder {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
}
</style>
