<template>
  <div class="site-bg">
    <div class="site-bgWrap">
      <div class="top-pink blob">
        <svg viewBox="0 0 200 200">
          <path
            class="top-pink-path"
            fill="#ffa6a6"
            d="M42.5,-52.8C53.7,-50.2,60.6,-36.1,63.9,-21.8C67.2,-7.4,66.9,7.1,64.2,22.5C61.5,37.9,56.3,54.2,45.2,62.2C34.1,70.3,17.1,70.1,5.6,62.4C-5.8,54.7,-11.7,39.4,-23.9,31.7C-36.2,24,-54.8,23.9,-61.8,16.7C-68.9,9.4,-64.3,-5,-57.3,-16.1C-50.3,-27.3,-40.8,-35.1,-30.8,-38.1C-20.8,-41.2,-10.4,-39.3,2.6,-42.9C15.6,-46.5,31.2,-55.4,42.5,-52.8Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
      <div class="bottom-pink blob">
        <svg viewBox="0 0 200 200">
          <path
            class="bottom-pink-path"
            fill="#fcffa0"
            d="M29.8,-24.1C39.8,-19.8,50,-9.9,48.2,-1.7C46.5,6.4,32.9,12.9,22.9,21.3C12.9,29.8,6.4,40.2,-5.5,45.7C-17.4,51.1,-34.7,51.6,-49.2,43.1C-63.8,34.7,-75.5,17.4,-78,-2.4C-80.4,-22.2,-73.5,-44.4,-59,-48.7C-44.4,-52.9,-22.2,-39.2,-6.1,-33.1C9.9,-26.9,19.8,-28.3,29.8,-24.1Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.site-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -10;
  .blob {
    position: absolute;
  }
  .top-pink {
    right: -10%;
    top: -30%;
    width: 55%;
    @include sp {
      right: -41%;
      top: -14%;
      width: 104%;
    }
    &-path {
      animation: blobColor 20s infinite alternate;
    }
  }
  .bottom-pink {
    left: -10%;
    bottom: -40%;
    width: 60%;
    @include sp {
      left: -23%;
      bottom: -28%;
      width: 125%;
    }
    &-path {
      animation: blobColor 20s infinite alternate-reverse;
    }
  }
}
@keyframes blobColor {
  /* 紫から緑へ */
  0% {
    fill: #ffa6a6;
  }
  25% {
    fill: #fcffa0;
  }
  50% {
    fill: #c2ffa3;
  }
  75% {
    fill: #94fffb;
  }
  100% {
    fill: #bd9dff;
  }
}
@keyframes stop2 {
  /* 緑から紫へ */
  0% {
    stop-color: #fcffa0;
  }
  100% {
    stop-color: #ffa6a6;
  }
}
</style>
