<template>
  <div class="topModal-title">{{ title }}</div>
  <div class="topModal-img" @click="openModal()">
    <div class="topModal-btn"></div>
    <img :src="getThumb(url)" alt="title" />
  </div>
  <transition name="anime" mode="out-in">
    <div class="topModal-modal" v-show="showModal">
      <div class="topModal-modal-wrap">
        <button class="topModal-modal-btn" @click="closeModal()">✕</button>
        <div class="topModal-modal-movie">
          <iframe
            loading="lazy"
            :src="getUrl(url)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    url: String,
    title: String,
  },
  methods: {
    getThumb(url) {
      return `http://img.youtube.com/vi/${url}/maxresdefault.jpg`;
    },
    getUrl(url) {
      return `https://www.youtube.com/embed/${url}`;
    },
    openModal() {
      this.showModal = true;
      var carouselTest = document.getElementsByClassName("carousel__track");
      console.log(carouselTest[0]);
      carouselTest[0].classList.add("modalOpen");
    },
    closeModal() {
      this.showModal = false;
      var carouselTest = document.getElementsByClassName("carousel__track");
      console.log(carouselTest[0]);
      carouselTest[0].classList.remove("modalOpen");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";
.topModal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include pc {
    row-gap: 50px;
  }
  @include sp {
    row-gap: 40px;
    flex-direction: column;
  }
  &-one {
    position: relative;
    // border: 2px solid #000;
    transition: all 200ms ease-in-out;
    @include pc {
      width: 313px;
    }
  }
  &-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 55px;
    height: 55px;
    // background-color: #fff;
    border: 2px solid #fff;
    border-radius: 100vh;
    transform: translate(0);
    filter: drop-shadow(6px 6px 5px rgba(#000, 0.2));
    z-index: 50;
    &::after {
      position: absolute;
      content: "";
      display: inline-block;
      margin: auto;
      width: 0;
      height: 0;
      top: 0;
      bottom: 0;
      right: -4px;
      left: 0;
      border-style: solid;
      border-width: 12.5px 0 12.5px 21.7px;
      border-color: transparent transparent transparent #fff;
    }
  }
  &-title {
    position: absolute;
    padding: 0.4em 0.6em;
    width: fit-content;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    @include pc {
      bottom: -0em;
      left: -0em;
      font-size: 0.8em;
    }
    @include sp {
      bottom: 0em;
      left: 0.2em;
      font-size: 0.8em;
    }
    z-index: 50;
  }
  &-img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 2px #000;
    background-color: #000;
    overflow: hidden;
    transition: all 200ms ease-in-out;
    filter: brightness(100%) drop-shadow(4px 5px 0 black);
    @include sp {
      transform: translateZ(0);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      filter: brightness(80%) drop-shadow(0 0 0 black);
      transform: translate(4px, 5px);
      transition: all 200ms ease-in-out;
      img {
        transform: scale(1.05);
        transition: all 200ms ease-in-out;
      }
    }
  }
  &-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(#000, 0.8);
    z-index: 200;

    &-wrap {
      position: relative;
      max-width: 800px;
      width: 90vw;
      @include sp {
        width: 100vw;
      }
    }
    &-btn {
      position: absolute;
      top: -1.3em;
      right: 0;
      color: #fff;
      font-size: 3em;
      @include sp {
        right: 0.3em;
        font-size: 2em;
      }
    }
    &-movie {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .anime-enter-active {
    .topModal-modal-movie {
      animation: anime 500ms ease-out;
    }
  }
  .anime-leave-active {
    .topModal-modal-movie {
      animation: anime 200ms ease-out reverse;
    }
  }
  @keyframes anime {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
