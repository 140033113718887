<template>
  <ul class="sns">
    <li class="sns-list instagram">
      <a><i class="fa-brands fa-instagram"></i></a>
    </li>
    <li class="sns-list twitter">
      <a><i class="fa-brands fa-twitter"></i></a>
    </li>
    <li class="sns-list youtube">
      <a><i class="fa-brands fa-youtube"></i></a>
    </li>
    <li class="sns-list note">
      <a></a>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/style/mixin.scss";
.sns {
  display: flex;
  justify-content: center;
  @include pc {
    margin-bottom: 40px;
    gap: 30px;
  }
  @include sp {
    margin-bottom: 40px;
    gap: 20px;
  }
  &-list {
    @include pc {
      width: 50px;
      height: 50px;
    }
    @include sp {
      width: 38px;
      height: 38px;
    }
    a {
      @include anchorBlock;
      transition: all 300ms ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }
    i::before {
      @include pc {
        font-size: 50px;
      }
      @include sp {
        font-size: 38px;
      }
    }
    &.note a {
      border-radius: 10px;
      background: {
        image: url("@/assets/img/note.svg");
        color: #fff;
        size: contain;
        repeat: no-repeat;
        position: center center;
      }
    }
  }
}
</style>
