<template>
  <!-- <carousel :settings="settings" :breakpoints="breakpoints" :wrapAround="true" :autoplay="5000">
    <slide v-for="item in works" :key="item">
      <top-modal :url="item.id" :title="item.title"></top-modal>
    </slide>
  </carousel> -->
  <carousel :settings="settings" :breakpoints="breakpoints" :wrapAround="true">
    <slide v-for="item in works" :key="item">
      <top-modal :url="item.id" :title="item.title"></top-modal>
    </slide>
  </carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import worksTop from "@/assets/json/works-top.json";
import topModal from "./topModal.vue";

export default {
  components: {
    Carousel,
    Slide,
    topModal,
  },
  data: () => ({
    works: worksTop,
    settings: {
      itemsToShow: 1.2,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      500: {
        itemsToShow: 2.2,
        snapAlign: "start",
      },
      // 1000px and up
      1000: {
        itemsToShow: 3.2,
        snapAlign: "start",
      },
    },
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/style/mixin.scss";

.carousel {
  @include pc {
    margin-right: calc(50% - 50vw);
  }
  @include sp {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
}
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel__track {
  &.modalOpen {
    transform: none !important;
  }
}
.carousel__slide {
  // padding-right: 20px;
  padding: 10px;
  color: white;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: none;
}

.slide {
  &-list {
    position: relative;
    width: 100%;
    height: 100%;
    // border: 2px solid #000;
    transition: all 200ms ease-in-out;
  }
  &-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 55px;
    height: 55px;
    // background-color: #fff;
    border: 2px solid #fff;
    border-radius: 100vh;
    filter: drop-shadow(6px 6px 5px rgba(#000, 0.2));
    z-index: 50;
    &::after {
      position: absolute;
      content: "";
      display: inline-block;
      margin: auto;
      width: 0;
      height: 0;
      top: 0;
      bottom: 0;
      right: -4px;
      left: 0;
      border-style: solid;
      border-width: 12.5px 0 12.5px 21.7px;
      border-color: transparent transparent transparent #fff;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 200ms ease-in-out;
    background-color: #000;
    box-shadow: 0 0 0 2px #000;
    transform: translate(0);
    filter: brightness(100%) drop-shadow(4px 5px 0 black);
    @include sp {
      transform: translateZ(0);
    }
    img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      filter: brightness(80%) drop-shadow(0 0 0 black);
      transform: translate(4px, 5px);
      transition: all 200ms ease-in-out;
      img {
        transform: scale(1.05);
        transition: all 200ms ease-in-out;
      }
    }
  }
  &-title {
    position: absolute;
    padding: 0.4em 0.6em 0.6em;
    bottom: -0.9em;
    left: -0.5em;
    width: fit-content;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    @include pc {
      font-size: 0.8em;
    }
    @include sp {
      font-size: 0.7em;
    }
    z-index: 50;
  }
  &-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000;
    z-index: 200;
  }
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
