import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueScrollTo from "vue-scrollto";
import "./assets/style/destyle.css";

createApp(App).use(store).use(router).use(router).use(VueScrollTo).mount("#app");
createApp(App).use(VueAxios, axios); //←追加
