<template>
  <site-gnav></site-gnav>
  <main>
    <site-bg></site-bg>
    <transition name="anime" mode="out-in">
      <router-view />
    </transition>
  </main>
  <site-footer></site-footer>
</template>

<script>
// @ is an alias to /src
import siteGnav from "@/components/siteGnav.vue";
import siteFooter from "@/components/siteFooter.vue";
import siteBg from "@/components/siteBg.vue";

export default {
  name: "HomeView",
  components: {
    siteGnav,
    siteFooter,
    siteBg,
  },
  methods: {
    setMeta(route) {
      // タイトルを設定
      if (route.meta.title) {
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if (route.meta.desc) {
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute("content", setDesc);
      }
    },
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    $route(route) {
      this.setMeta(route);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/mixin.scss";

@font-face {
  src: url("@/assets/fonts/PollerOne-Regular.woff") format("woff");
  font-family: "Poller One";
  font-style: normal;
  font-display: swap;
}
@font-face {
  src: url("@/assets/fonts/NotoSansJP-Regular.woff") format("woff");
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: swap;
}
@font-face {
  src: url("@/assets/fonts/NotoSansJP-Bold.woff") format("woff");
  font-family: "Noto Sans JP";
  font-style: bold;
  font-display: swap;
}

body {
  background-color: #f8f8f8;
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  @include pc {
    font-size: 1rem; //18px
  }
  @include sp {
    font-size: 0.812rem; //13px
  }
}
main {
  position: relative;
  min-height: 100vh;
  @include pc {
    margin-top: 70px;
  }
  @include sp {
    margin-top: 60px;
  }
  p {
    line-height: 1.5;
  }
}
.anime-enter-active {
  animation: anime 200ms ease-out;
}
.anime-leave-active {
  animation: anime 200ms ease-out reverse;
}

@keyframes anime {
  0% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
</style>
