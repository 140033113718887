<template>
  <footer class="footer">
    <site-sns></site-sns>
    <div class="footer-text">
      <small>©2023 MIZUKI ENOGU</small>
    </div>
  </footer>
</template>

<script>
import siteSns from "@/components/siteSns.vue";

export default {
  components: {
    siteSns,
  },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.footer {
  position: relative;
  background-color: #000;
  color: #fff;
  text-align: center;
  @include pc {
    padding: 50px 0 30px;
  }
  @include sp {
    padding: 30px 20px 20px;
  }
  .sns {
    display: flex;
    justify-content: center;
    @include pc {
      margin-bottom: 40px;
      gap: 30px;
    }
    @include sp {
      margin-bottom: 40px;
      gap: 20px;
    }
    &-list {
      @include pc {
        width: 50px;
        height: 50px;
      }
      @include sp {
        width: 38px;
        height: 38px;
      }
      a {
        @include anchorBlock;
        transition: all 300ms ease-in-out;
        &:hover {
          opacity: 0.5;
        }
      }
      i::before {
        @include pc {
          font-size: 50px;
        }
        @include sp {
          font-size: 38px;
        }
      }
      &.note a {
        border-radius: 10px;
        background: {
          image: url("@/assets/img/note.svg");
          color: #fff;
          size: contain;
          repeat: no-repeat;
          position: center center;
        }
      }
    }
  }
}
</style>
