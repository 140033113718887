<template>
  <div class="siteAnchor" :class="[type, color]">
    <a :href="link"
      ><span>{{ text }}</span></a
    >
  </div>
</template>

<script>
export default {
  name: "siteAnchor",
  data() {
    return {};
  },
  props: {
    text: { type: String, default: "more" },
    link: { type: String },
    type: { type: String },
    color: { type: String },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/style/mixin.scss";

.siteAnchor {
  position: relative;
  margin: auto;

  @include font;
  @include pc {
    margin-top: 40px;
    width: 300px;
    font-size: 1.375rem;
  }
  @include sp {
    margin-top: 30px;
    width: 200px;

    font-size: 1.06rem;
  }
  a {
    background-color: #000;
    text-align: center;
    color: #fff;
    // border-bottom: 1px solid #fff;
    // filter: drop-shadow(0 7px 0 #000);
    transition: 300ms;
    @include anchorBlock;
    @include pc {
      padding: 1em 1em 1.3em;
    }
    @include sp {
      padding: 1em 1em 1.3em;
    }
    &::after {
      position: absolute;
      content: "";
      display: block;
      bottom: 0.3em;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transition: 300ms;
    }
    &:hover {
      padding: 1.15em 1em 1.15em;
      &::after {
        bottom: 0;
      }
    }
  }
  &.rainbow {
    margin: auto;
    @include pc {
      width: 300px;
    }
    @include sp {
      margin-top: 30px;
      width: 170px;
    }
  }
  &.rainbow a {
    position: relative;
    background-color: transparent;
    color: transparent;
    // border: solid;
    // border-image: linear-gradient(90deg, #ffa6a6 0%, #fcffa0 25%, #c2ffa3 50%, #94fffb 75%, #bd9dff 100%) 1/2px;
    transition: 300ms;
    @include rainbow;
    z-index: 0;
    &::after {
      width: 100%;
      height: 1px;
      left: 0;
      background-color: #000;
      z-index: 1;
      transition: 300ms;
      @include pc {
        height: 2px;
      }
    }
    > span {
      color: #000;
    }
    &:hover {
      &::after {
        bottom: 0;
      }
    }
  }
  &.news {
    @include pc {
      margin: 0 auto 0 0;
      width: 100%;
    }
  }
  &.contact {
    margin: auto;
    @include pc {
      margin-top: 40px;
      width: 660px;
    }
    @include sp {
      margin-top: 30px;
      width: 100%;
    }
  }
  &.contact a {
    position: relative;
    width: 100%;
    color: transparent;
    > span {
      @include rainbow;
      background-clip: text;
    }
  }
}
</style>
