<template>
  <section class="section" :class="type">
    <slot />
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    type: { type: String },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.section {
  position: relative;
  @include pc {
    margin: 0 auto 100px;
    min-width: 900px;
    max-width: 1200px;
    padding: 0 40px;
  }
  @include sp {
    margin-bottom: 60px;
    padding: 0 20px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 50px;
  }
  &.black {
    background-color: #000;
    color: #fff;
    @include pc {
      margin-top: 130px;
      margin-bottom: 70px;
      padding: 70px 60px 70px 40px;
    }
    @include sp {
      margin: 80px 20px 40px 0;
      padding: 50px 30px 40px 20px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(50% - 50vw);
      width: 50vw;
      height: 100%;
      background-color: #000;
      z-index: -1;
    }
  }
  &.rainbow {
    width: 100%;
    min-width: 100vw;
    background-image: linear-gradient(90deg, rgba(#ffa6a6, 0.7) 0%, rgba(#fcffa0, 0.7) 25%, rgba(#c2ffa3, 0.7) 50%, rgba(#94fffb, 0.7) 75%, rgba(#bd9dff, 0.5) 100%);
    @include sp {
      margin-top: 80px;
    }
  }
}
</style>
