<template>
  <div class="home">
    <site-mv></site-mv>
    <site-section class="news">
      <div class="news-wrap">
        <div>
          <site-heading2 title="News" class="scroll-target"></site-heading2>
          <site-anchor type="news" class="isPC" link="#/news"></site-anchor>
        </div>
        <top-newslist></top-newslist>
      </div>
      <site-anchor type="news" class="isSP" link="#/news"></site-anchor>
    </site-section>
    <site-section type="black" class="profile">
      <site-heading2 title="Profile" color="rainbow" class="scroll-target"></site-heading2>
      <div class="profile-wrap">
        <div class="profile-img"></div>
        <div>
          <div class="profile-textBox">
            <div class="profile-text">
              <p class="name">瑞貴 えのぐ<span class="name-kana">MIZUKI ENOGU</span></p>
              <p>2018年より3年間、2.5次元ミュージカルを中心に役者・ダンサーとして出演。 <br />2023年春より音楽活動開始。</p>
            </div>
            <site-anchor color="rainbow" link="#/profile"></site-anchor>
          </div>
        </div>
      </div>
    </site-section>
    <site-section class="works">
      <div class="heading-desc">
        <site-heading2 title="Works" class="scroll-target"></site-heading2>
        <p>オリジナル曲・イラストなど</p>
      </div>
      <div class="works-carouselWrap">
        <top-carousel></top-carousel>
      </div>
      <site-anchor link="#/works"></site-anchor>
    </site-section>
    <site-section type="rainbow" class="contact">
      <site-heading2 title="Contact" class="topContact scroll-target"></site-heading2>
      <p class="center">楽曲提供・出演・イラスト制作などのご依頼は、下記メールフォームより承ります。</p>
      <site-anchor text="Contact" type="contact" link="/contactform.php"></site-anchor>
    </site-section>
  </div>
</template>

<script>
// @ is an alias to /src
import siteAnchor from "@/components/siteAnchor.vue";
import siteMv from "@/components/siteMv.vue";
import siteSection from "@/components/siteSection.vue";
import siteHeading2 from "@/components/siteHeading2.vue";
import TopNewslist from "@/components/topNewslist.vue";
import TopCarousel from "@/components/topCarousel.vue";

export default {
  name: "HomeView",
  components: {
    siteAnchor,
    siteMv,
    siteSection,
    siteHeading2,
    TopNewslist,
    TopCarousel,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.news {
  &-wrap {
    @include pc {
      display: flex;
      justify-content: space-between;
      gap: 50px;
    }
  }
}
.profile {
  .heading2 {
    position: absolute;
    @include pc {
      top: -44px;
    }
    @include sp {
      top: -30px;
    }
  }
  &-wrap {
    @include pc {
      display: flex;
      gap: 40px;
    }
  }
  &-img {
    background-color: aquamarine;
    @include pc {
      width: 400px;
      height: 300px;
    }
    @include sp {
      width: 100%;
      margin-bottom: 20px;
      padding-top: 75%;
    }
  }
  &-textBox {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &-text {
    font-size: 1em;
    line-height: 1.6;
    flex: 1;
    .name {
      margin-bottom: 1.5em;
      font-weight: bold;
      font-size: 1.3em;
    }
    .name-kana {
      margin-left: 1em;
      font-weight: normal;
      @include googleFont;
      font-size: 0.9em;
    }
  }
}
.heading-desc {
  @include pc {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
.works-carouselWrap {
  position: relative;
  @include sp {
    margin-top: 1em;
  }
}
.slide-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(#000, 0.8);
  z-index: 200;
}
</style>
