<template>
  <h2 class="heading2" :class="color">
    <span>{{ title }}</span>
  </h2>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: { type: String, default: "News" },
    color: { type: String, default: "" },
  },
  methods: {},
  mounted() {
    var lists = document.querySelectorAll(".scroll-target");
    lists.forEach((list) => {
      window.addEventListener("scroll", () => {
        const rect = list.getBoundingClientRect().top;
        const innerHeight = window.innerHeight;
        if (innerHeight - rect > 100) {
          list.classList.add("show");
        } else {
          list.classList.remove("show");
        }
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.heading2 {
  position: relative;
  width: fit-content;
  margin-bottom: 1rem;
  overflow: hidden;
  @include googleFont;
  transition: all 200ms ease-in;
  @include pc {
    font-size: 4.16em;
  }
  @include sp {
    font-size: 4.23em;
  }
  &.contact {
    margin: auto;
  }
  &.topContact {
    margin: auto;
    top: -0.5em;
  }
  &.scroll-target {
    opacity: 0;
    > span {
      opacity: 0;
      transition: all 100ms 400ms;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    @include rainbow;
    transform: translateX(-100%);
  }
  &.show {
    opacity: 1;
    > span {
      opacity: 1;
    }
    &::after {
      animation: h2Black 600ms ease-in-out forwards;
    }
  }
  &.rainbow {
    &::after {
      @include rainbow;
    }
    > span {
      color: transparent;
      background-image: linear-gradient(90deg, #ffa6a6 0%, #fcffa0 25%, #c2ffa3 50%, #94fffb 75%, #bd9dff 100%);
      background-clip: text;
      -webkit-text-stroke: 2px #000;
    }
  }
}

@keyframes h2Black {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(101%);
  }
}
</style>
