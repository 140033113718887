import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "瑞貴えのぐ オフィシャルサイト",
      desc: "「瑞貴えのぐ」オフィシャルサイト。最新情報の他、オリジナル曲やイラストを掲載しています。",
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import(/* webpackChunkName: "about" */ "../views/News.vue"),
    meta: {
      title: "News | 瑞貴えのぐ オフィシャルサイト",
      desc: "「瑞貴えのぐ」の最新情報を掲載しています。",
    },
  },
  {
    path: "/article",
    name: "article",
    component: () => import(/* webpackChunkName: "about" */ "../views/Article.vue"),
    children: [
      {
        path: "2023-01-04",
        component: () => import(/* webpackChunkName: "about" */ "../views/Article-230104.vue"),
      },
    ],
    meta: {
      title: "News | 瑞貴えのぐ オフィシャルサイト",
      desc: "「瑞貴えのぐ」の最新情報を掲載しています。",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
    meta: {
      title: "Profile | 瑞貴えのぐ オフィシャルサイト",
      desc: "「瑞貴えのぐ」のプロフィールを掲載しています。",
    },
  },
  {
    path: "/works",
    name: "works",
    component: () => import(/* webpackChunkName: "about" */ "../views/Works.vue"),
    meta: {
      title: "Works | 瑞貴えのぐ オフィシャルサイト",
      desc: "「瑞貴えのぐ」の作品を掲載しています。",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
