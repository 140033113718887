<template>
  <div>
    <header class="header">
      <div class="header-wrap">
        <h1 class="header-title">
          <a href="/">MIZUKI ENOGU</a>
        </h1>
        <button class="toggle isSP" @click="toggleGnav" aria-label="toggle">
          <div class="toggle-btn" :class="{ close: isGnav }"><span></span><span></span><span></span></div>
        </button>
        <nav class="gnav isPC">
          <ul class="gnav-list">
            <li class="gnav-list-one" :class="item.name" v-for="item in gnavList" :key="item.name">
              <router-link :to="item.link">{{ item.name }}</router-link>
            </li>
            <li class="gnav-list-one contact"><a href="./contact.php">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
    <transition name="slide">
      <nav class="gnav isSP" v-if="isGnav">
        <div class="gnav-wrap">
          <ul class="gnav-list">
            <li class="gnav-list-one"><a /></li>
            <li class="gnav-list-one" v-for="item in gnavList" :class="item.name" :key="item.name" @click="toggleGnav">
              <router-link :to="item.link">{{ item.name }}</router-link>
            </li>
            <li class="gnav-list-one contact"><a href="./contact.php">Contact</a></li>
            <li class="gnav-list-one"><a /></li>
          </ul>
          <div class="sns-wrap">
            <div class="sns-heading">Follow</div>
            <site-sns></site-sns>
          </div>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
import siteSns from "@/components/siteSns.vue";

export default {
  components: {
    siteSns,
  },
  data() {
    return {
      gnavList: [
        { name: "Home", link: "/" },
        { name: "News", link: "/news" },
        { name: "Profile", link: "/profile" },
        { name: "Works", link: "/works" },
      ],
      isGnav: false,
    };
  },
  props: [],
  methods: {
    toggleGnav() {
      this.isGnav = !this.isGnav;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/style/mixin.scss";

.header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  border-bottom: 3px solid #000;
  @include googleFont;
  z-index: 100;
  @include pc {
    height: 70px;
  }
  @include sp {
    height: 60px;
  }
  &::after {
    @include pc {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 8px;
      border-top: 2px solid #000;
      transition: 300ms;
      z-index: -1;
    }
  }

  &-wrap {
    // outline: 1px solid red;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @include pc {
      width: 90vw;
      min-width: 700px;
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  &-title {
    position: relative;
    background-color: #fff;
    flex: 1;
    @include pc {
      width: 30%;
      height: 100%;
      padding: 0 0.5em 0.2em 1em;
      font-size: clamp(16px, 2vw, 1.5em);
      border-left: 2px solid #000;
    }
    @include sp {
      padding-left: 0.5em;
      font-size: 2.07em;
    }
    a {
      @include pc {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transition: 300ms;
      }
      &::after {
        @include pc {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 8px;
          border-top: 2px solid #000;
          transition: 300ms;
        }
      }
      &:hover {
        @include pc {
          padding-top: 10px;
        }
      }
      &:hover::after {
        @include pc {
          height: 0;
          border-top: 1px solid #000;
        }
      }
    }
  }
}
.gnav {
  position: relative;
  @include googleFont;
  height: 100%;
  @include pc {
    width: 70%;
  }
  @include sp {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 60px;
    right: 0;
    backdrop-filter: brightness(0.5);
    z-index: 100;
  }
  &-wrap {
    @include sp {
      position: relative;
      margin: 0 0 0 auto;
      width: 80%;
      height: 100%;
      right: 0;
      border-left: 3px solid #000;
      background-color: #fff;
    }
  }
  &-list {
    @include pc {
      display: flex;
      height: 100%;
      border-right: 2px solid #000;
    }
  }
  &-list-one {
    position: relative;
    background-color: #fff;
    @include pc {
      width: calc(100% / 5);
      min-width: 100px;
      height: 100%;
      border-left: 2px solid #000;
      font-size: clamp(14px, 1.5vw, 1.11em);
    }
    @include sp {
      height: 55px;
      font-size: 1.84em;
      border-bottom: 2px solid #000;
    }
    &::before {
      content: "";
      position: absolute;
      background-color: #000;
      z-index: 10;
      @include pc {
        width: 20px;
        height: 30px;
        left: -10px;
        top: 0;
      }
      @include sp {
        width: 110px;
        height: 25px;
        right: 0;
        bottom: -13px;
      }
    }
    &.Profile::before {
      @include pc {
        display: none;
      }
    }
    &.News::before,
    &.Contact::before,
    &:last-child:before {
      @include sp {
        display: none;
      }
    }
    &.contact::after {
      @include pc {
        content: "";
        position: absolute;
        background-color: #000;
        width: 20px;
        height: 30px;
        right: -10px;
        top: 0;
      }
    }
    > a {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      transition: 300ms;
      @include pc {
        justify-content: center;
      }
      @include sp {
        padding-left: 2.5rem;
      }
      &::after {
        content: "";
        position: absolute;
        transition: 300ms;
        @include pc {
          bottom: 0;
          width: 100%;
          height: 8px;
          border-top: 2px solid #000;
        }
        @include sp {
          left: 0;
          width: 10px;
          height: 100%;
          border-right: 2px solid #000;
        }
      }
      &:hover {
        @include pc {
          padding-top: 10px;
        }
      }
      &:hover::after {
        @include pc {
          height: 0;
          border-top: 1px solid #000;
        }
      }
    }
  }
  .sns {
    &-wrap {
      margin-top: 3em;
      text-align: center;
    }
    &-heading {
      margin-bottom: 0.7em;
      font-size: 1.7em;
    }
  }
}
.toggle {
  @include pc {
    display: none;
  }
  position: relative;
  display: block;
  width: 60px;
  height: 100%;
  padding: 19px 16px;
  border-left: 2px solid #000;
  // outline: 1px solid red;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: -10px;
    width: 3px;
    height: 100%;
    background-color: #000;
  }
  &-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    span {
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: #000;
      transition: 150ms ease-in-out;
    }
    &.close {
      margin-left: 3px;
      span:nth-child(1) {
        transform: rotate(40deg);
        transform-origin: left top;
      }
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        transform: rotate(-40deg);
        transform-origin: left bottom;
      }
    }
  }
}
.slide-enter-active {
  animation: opacity 0.3s ease-in-out;
  .gnav-wrap {
    animation: slide 0.3s ease-in-out;
  }
}
.slide-leave-active {
  animation: opacity 0.2s reverse ease-in-out;
  .gnav-wrap {
    animation: slide 0.2s reverse ease-in-out;
  }
}
@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
